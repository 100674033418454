<template>
  <div class="button_edit" v-if="block">
    <div class="">

      <div class="back-button-section">
        <div v-b-toggle.back-button-collapse style="background: #F7F7F7;  cursor: pointer; border-bottom: 1px solid #D2D8E0; border-top: 1px solid #D2D8E0;" class="button-editor-heading px-3 py-2 d-flex justify-content-between align-items-center">
          <div style="color: #18191C; font-weight: 500; font-size: 16px" class="">Back button settings</div>
          <div class="button-chevron" style="color: #B3AFB6; font-weight: 500; font-size: 16px" >
          <i class="fa fa-chevron-down " aria-hidden="true"></i>
        </div>
        </div>
        <b-collapse accordion="button-accordion" id="back-button-collapse" class="mt-2">
    <div class="px-3">
      <label class="editorStyleTitle"  >Button text:</label>
  <input v-if="block.content.backButton" type="text" v-model="block.content.backButton.text"  placeholder="Button Text" class="editor-input-control w-100">
  <div class="mt-2"  >
      <label for="" class=" mt-2 editorStyleTitle d-flex align-items-center justify-content-between">
        <span>Hide Back Button:</span>
        <b-form-checkbox
        :id="`id-backbutton-${Date.now()}`"
            v-model="block.content.backButton.hideBackButton"
            :disabled="hideNextButton"
                  ></b-form-checkbox>
      </label>
      <i class="text-danger d-block mt-1" style="font-size: 12px;" v-if="hideNextButton">CANNOT HIDE BACK BUTTON IF NEXT BUTTON IS HIDDEN</i>
    </div>    
</div>
<hr>
<div class="px-3">
  <label for="button-text" class="editor-label">Button size: {{ !isDesktopView?'(Mobile)':'' }}</label>
  <div class="d-flex gap">
<div class="d-flex align-items-center" title="Width (px)" style="gap:4px">
<span class="" style="color: #B3AFB6;" >W</span>
  <input type="number"  v-if="isDesktopView" v-model="block.content.backButton.style.buttonWidth"  placeholder="Width" class="editor-input-control w-100 input-bg-color border-0">
  <input type="number" v-else v-model="block.content.backButton.style.mobileButtonWidth"  placeholder="Width" class="editor-input-control w-100 input-bg-color border-0">
</div>
<div class="d-flex align-items-center" title="Height (px)" style="gap:4px">
<span class="" style="color: #B3AFB6;">H</span>
  <input type="number" v-if="isDesktopView"  v-model="block.content.backButton.style.buttonHeight" placeholder="Height" class="editor-input-control w-100 input-bg-color border-0">
  <input type="number" v-else  v-model="block.content.backButton.style.mobileButtonHeight" placeholder="Height" class="editor-input-control w-100 input-bg-color border-0">
</div>
  </div>
 </div>
 <hr>

  <div class="px-3">
  <FontFamily  v-model="block.content.backButton.style.fontFamily"></FontFamily>
  <div class="my-3 d-flex gap align-items-center" title="Font size (px)">
    <div class="d-flex align-items-center" style="gap:4px">
<span class="" style="color: #B3AFB6;"><svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z" fill="#B3AFB6"></path><path d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z" fill="#B3AFB6"></path></svg></span>
  <input type="number" v-model="block.content.backButton.style.fontSize" placeholder="Font size" class="editor-input-control w-100 input-bg-color border-0">
</div>
    <FontStyles :textBold.sync="block.content.backButton.style.fontWeight" 
  :textItalic.sync="block.content.backButton.style.fontStyle"
  :textUnderLine.sync="block.content.backButton.style.textDecoration" class=""></FontStyles>
   
  </div>
  <div class="d-flex flex-column gap">
    <TextAlign v-model="block.content.backButton.style.textAlign" ></TextAlign>
  <label v-if="hideButtonPosition" for="button-text" class="editor-label">Button Position:</label>
    <div v-if="hideButtonPosition" class="d-flex text-align-section">
      <label title="Start" for="left" @click="block.style.buttonPosition='start'" :class="block.style.buttonPosition=='start'?'active-editor-align':''" class="editor-text-left-control">
        <svg
                width="22"
                height="18"
                viewBox="0 0 22 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 16H2V2H20V16ZM3 12H12V15H3V12Z"
                  fill="currentColor"
                />
              </svg>
          </label>
          <label title="Center" for="center"  @click="block.style.buttonPosition='center'" :class="block.style.buttonPosition=='center'?'active-editor-align':''"  class="editor-text-center-control">
            <svg
                width="22"
                height="18"
                viewBox="0 0 22 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 16H2V2H20V16ZM6 12H16V15H6V12Z"
                  fill="currentColor"
                />
              </svg>
          </label>
          <label for="end" title="End" @click="block.style.buttonPosition='end'" :class="block.style.buttonPosition=='end'?'active-editor-align':''" class="editor-text-right-control">
            <svg
                width="22"
                height="18"
                viewBox="0 0 22 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 16H2V2H20V16ZM10 12H19V15H10V12Z"
                  fill="currentColor"
                />
              </svg>
          </label>
       
    </div>
  </div>
 </div>
 <hr>
<div class="px-3">
  <SelectColor v-model="block.content.backButton.style.backgroundColor" :opacity.sync="block.content.backButton.style.backgroundOpacity"  :label="'Button Color'"></SelectColor>
  <SelectColor v-model="block.content.backButton.style.color"  :opacity.sync="block.content.backButton.style.textOpacity" class="mt-3" :label="'Text Color'"></SelectColor>
</div>
<hr>
 <div class="px-3">
  <label for="" class="editor-label">Button stroke:</label>
  <Stroke  :borderSize.sync="block.content.backButton.style.borderSize"
  :borderRadius.sync="block.content.backButton.style.borderRadius"  ></Stroke>
 </div>
  </b-collapse>
 
        <div v-b-toggle.next-button-collapse style="background: #F7F7F7;  cursor: pointer; border-bottom: 1px solid #D2D8E0; border-top: 1px solid #D2D8E0;" class="button-editor-heading px-3 py-2 d-flex justify-content-between align-items-center">
          <div style="color: #18191C; font-weight: 500; font-size: 16px" class="">Next button settings</div>
          <div class="button-chevron" style="color: #B3AFB6; font-weight: 500; font-size: 16px" >
          <i class="fa fa-chevron-down " aria-hidden="true"></i>
        </div>
        </div>
       
  <b-collapse accordion="button-accordion" id="next-button-collapse" class="mt-2">
    <div class="px-3">
      <label class="editorStyleTitle"  >Button text:</label>
  <input  type="text" v-model="block.content.nextButton.text"  placeholder="Button Text" class="editor-input-control w-100">
  <div  v-if="pageType == 'singleAnswer'" class="mt-2"  >
      <label for="" class=" mt-2 editorStyleTitle d-flex align-items-center justify-content-between">
        <span>Hide Next Button:</span>
        <b-form-checkbox
        :id="`id-nextButton-${Date.now()}`"
             v-model="block.content.nextButton.hideNextButton"
            :disabled="hideBackButton"
                  ></b-form-checkbox>
      </label>
      <i class="text-danger d-block mt-1" style="font-size: 12px;" v-if="hideBackButton">CANNOT HIDE NEXT BUTTON IF BACK BUTTON IS HIDDEN</i>
    </div> 
</div>
<hr>
<div class="px-3">
  <label for="button-text" class="editor-label">Button size: {{ !isDesktopView?'(Mobile)':'' }}</label>
  <div class="d-flex gap">
<div class="d-flex align-items-center" title="Width (px)" style="gap:4px">
<span class="" style="color: #B3AFB6;" >W</span>
  <input type="number"  v-if="isDesktopView" v-model="block.content.nextButton.style.buttonWidth"  placeholder="Width" class="editor-input-control w-100 input-bg-color border-0">
  <input type="number" v-else v-model="block.content.nextButton.style.mobileButtonWidth"  placeholder="Width" class="editor-input-control w-100 input-bg-color border-0">
</div>
<div class="d-flex align-items-center" title="Height (px)" style="gap:4px">
<span class="" style="color: #B3AFB6;">H</span>
  <input type="number" v-if="isDesktopView"  v-model="block.content.nextButton.style.buttonHeight" placeholder="Height" class="editor-input-control w-100 input-bg-color border-0">
  <input type="number" v-else  v-model="block.content.nextButton.style.mobileButtonHeight" placeholder="Height" class="editor-input-control w-100 input-bg-color border-0">
</div>
  </div>
 </div>
 <hr>

  <div class="px-3">
  <FontFamily  v-model="block.content.nextButton.style.fontFamily"></FontFamily>
  <div class="my-3 d-flex gap align-items-center" title="Font size (px)">
    <div class="d-flex align-items-center" style="gap:4px">
<span class="" style="color: #B3AFB6;"><svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z" fill="#B3AFB6"></path><path d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z" fill="#B3AFB6"></path></svg></span>
  <input type="number" v-model="block.content.nextButton.style.fontSize" placeholder="Font size" class="editor-input-control w-100 input-bg-color border-0">
</div>
    <FontStyles :textBold.sync="block.content.nextButton.style.fontWeight" 
  :textItalic.sync="block.content.nextButton.style.fontStyle"
  :textUnderLine.sync="block.content.nextButton.style.textDecoration" class=""></FontStyles>
   
  </div>
<TextAlign v-model="block.content.nextButton.style.textAlign" ></TextAlign>
<label v-if="hideButtonPosition" for="button-text" class="editor-label">Button Position:</label>
    <div v-if="hideButtonPosition" class="d-flex mt-2 text-align-section">
      <label title="Start" for="left" @click="block.style.buttonPosition='start'" :class="block.style.buttonPosition=='start'?'active-editor-align':''" class="editor-text-left-control">
        <svg
                width="22"
                height="18"
                viewBox="0 0 22 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 16H2V2H20V16ZM3 12H12V15H3V12Z"
                  fill="currentColor"
                />
              </svg>
          </label>
          <label title="Center" for="center"  @click="block.style.buttonPosition='center'" :class="block.style.buttonPosition=='center'?'active-editor-align':''"  class="editor-text-center-control">
            <svg
                width="22"
                height="18"
                viewBox="0 0 22 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 16H2V2H20V16ZM6 12H16V15H6V12Z"
                  fill="currentColor"
                />
              </svg>
          </label>
          <label for="end" title="End" @click="block.style.buttonPosition='end'" :class="block.style.buttonPosition=='end'?'active-editor-align':''" class="editor-text-right-control">
            <svg
                width="22"
                height="18"
                viewBox="0 0 22 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 16H2V2H20V16ZM10 12H19V15H10V12Z"
                  fill="#6D6B6D"
                />
              </svg>
          </label>
       
    </div>
 </div>
 <hr>
<div class="px-3">
  <SelectColor v-model="block.content.nextButton.style.backgroundColor" :opacity.sync="block.content.nextButton.style.backgroundOpacity"  :label="'Button Color'"></SelectColor>
  <SelectColor v-model="block.content.nextButton.style.color"  :opacity.sync="block.content.nextButton.style.textOpacity" class="mt-3" :label="'Text Color'"></SelectColor>
</div>
<hr>
 <div class="px-3">
  <label for="" class="editor-label">Button stroke:</label>
  <Stroke  :borderSize.sync="block.content.nextButton.style.borderSize"
  :borderRadius.sync="block.content.nextButton.style.borderRadius"  ></Stroke>
 </div>
  </b-collapse>
  <hr>
  <div class="px-3">
  <padding  v-if="isDesktopView" :right.sync="block.style.marginRight" :left.sync="block.style.marginLeft" :top.sync="block.style.marginTop" :bottom.sync="block.style.marginBottom"></padding>
  <padding  v-else :label="'Spacing (Mobile)'"  :right.sync="block.style.mobileMarginRight" :left.sync="block.style.mobileMarginLeft" :top.sync="block.style.mobileMarginTop" :bottom.sync="block.style.mobileMarginBottom"></padding> 
</div>
      </div>
    </div>
  </div>
</template>
<script>

import fontFamilyList from "../../../staticPages/fontFamilyList.js";
import FontFamily from "../MiniComponents/FontFamily.vue";
import FontStyles from "../MiniComponents/FontStyles.vue";
import Padding from "../MiniComponents/Padding.vue";
import SelectColor from "../MiniComponents/SelectColor.vue";
import Stroke from "../MiniComponents/Stroke.vue";
import TextAlign from "../MiniComponents/TextAlign.vue";

export default {
  components: {
    FontFamily,
    FontStyles,
    TextAlign,
    SelectColor,
    Stroke,
    Padding
  },
  props: {
    block: Object,
    index: Number,
    pageType: String,
    isDesktopView: Boolean,
  },
  data() {
    return {
      showBgOpacityBack: true,
      showBgOpacityNext: true,
    };
  },
  methods: {
    checkBackBtnHexCode(color) {
      if (color) {
        let pattern = /^#[0-9A-F]{6}$/i;
        return color.match(pattern) ? true : false;
      } else {
        return false;
      }
    },
    BackBtnBgColorOutput(value) {
      let isHex = this.checkBackBtnHexCode(value);
      if (isHex) {
        this.showBgOpacityBack = true;
        return value;
      } else {
        this.showBgOpacityBack = false;
        return "Gradient input";
      }
    },
    checkNextBtnHexCode(color) {
      if (color) {
        let pattern = /^#[0-9A-F]{6}$/i;
        return color.match(pattern) ? true : false;
      } else {
        return false;
      }
    },
    NextBtnBgColorOutput(value) {
      let isHex = this.checkNextBtnHexCode(value);
      if (isHex) {
        this.showBgOpacityNext = true;
        return value;
      } else {
        this.showBgOpacityNext = false;
        return "Gradient input";
      }
    },
  },
  computed: {
    getFontFamilyList() {
      return fontFamilyList;
    },
    hideNextButton() {
      if (
        this.pageType == "singleAnswer" &&
        this.block.content.nextButton.hideNextButton
      )
        return true;
      return false;
    },
    hideBackButton() {
      if (this.pageType == "stripePage") return false;
      if (this.block.content.backButton.hideBackButton) return true;
      return false;
    },
    hideButtonPosition() {
      if (this.pageType == "stripePage") return true;

      if (
        this.pageType == "multipleAnswer" &&
        this.block.content.backButton.hideBackButton
      )
        return true;
      if (
        this.pageType !== "multipleAnswer" &&
        (this.block.content.nextButton.hideNextButton ||
          this.block.content.backButton.hideBackButton)
      )
        return true;
      return false;
    },
    backBtnFontFamily(){
      return this.block.content.backButton.style.fontFamily
    },
    nextBtnFontFamily(){
      return this.block.content.nextButton.style.fontFamily
    },
  },
  watch:{
    backBtnFontFamily(newVal,oldVal){
      if(oldVal && newVal){
        this.$store.dispatch('updateFontFamily', { oldFontFamily:oldVal, newFontFamily:newVal });
        this.$store.dispatch('loadFontsFromTracker');
      }
    },
    nextBtnFontFamily(newVal,oldVal){
      if(oldVal && newVal){
        this.$store.dispatch('updateFontFamily', { oldFontFamily:oldVal, newFontFamily:newVal });
        this.$store.dispatch('loadFontsFromTracker');
      }
    },
  },
};
</script>
<style scoped>
.form-control-sn {
  border: 1px solid;
  width: 100%;
  border-radius: 3px;
  /* line-height: 1rem; */
  height: 26px;
  font-size: 14px;
}

.active-svg {
  padding: 3px;
  background: #b3afb6;
  border-radius: 3px;
}
.active-svg path {
  fill: #323232;
}

.diabledClass {
  opacity: 0.3 !important;
}
.text-align-section {
  color: #b3afb6;
  background: #f7f7f7;
  width: fit-content;
  border-radius: 6px;
}
.text-align-section label {
  padding: 6px 12px;
  border: 1px solid transparent;
  margin: 0px;
}
.text-align-section label:hover {
  color: #000;
}
.active-editor-align {
  border-radius: 6px;
  border: 1px solid #d2d8e0!important;
  background: #fff;
}
.button-chevron{
  transition: 0.3s ease all;
}

</style>
<style>
:not(.collapsed) > .button-chevron {
  transform: rotate(180deg);
}
.collapsed > .button-chevron {
  transform: rotate(0deg);
}
</style>
